import { Component, Vue } from "vue-property-decorator";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfPageEmailChangeComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  beforeMount() {
    const emailToken: string = <any>this.$route.query.token || null;
    const email: string = <any>this.$route.query.email || null;
    this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias }, query: { emailToken: emailToken, email: email } });
  }
}
